import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {CssBaseline, ThemeProvider} from '@mui/material';
import {App} from './app';
import {createRoot} from 'react-dom/client';
import {IntlProvider} from '@eolymp/ui/dist/intl';
import {BackendProvider} from '@eolymp/ui/dist/backend';
import i18n from './i18n';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {UserProvider} from '@eolymp/ui/dist/user';
import {AuthenticationProvider} from '@eolymp/ui/dist/authn';
import {Theme} from './components/app/Theme';
import {MustAuthenticate} from './components/app/MustAuthenticate';

const auth = {
  clientId: 'developer',
  scope: 'refresh_token cognito:access-key:read cognito:access-key:write',
  tokenEndpoint: 'https://api.eolymp.com/oauth/token',
  authorizeEndpoint: 'https://accounts.eolymp.com/authorize',
  userinfoEndpoint: 'https://api.eolymp.com/oauth/userinfo',
};

const container = document.getElementById('root');
const root = createRoot(container!);

root.render((
  <React.StrictMode>
    <CssBaseline />
    <BrowserRouter>
      <IntlProvider locales={i18n.locales} loader={i18n.get}>`
        <LocalizationProvider dateAdapter={AdapterMoment}>`
          <BackendProvider auth={auth}>
            <ThemeProvider theme={Theme}>
              <AuthenticationProvider>
                <UserProvider>
                  <MustAuthenticate>
                    <App />
                  </MustAuthenticate>
                </UserProvider>
              </AuthenticationProvider>
            </ThemeProvider>
          </BackendProvider>
        </LocalizationProvider>
      </IntlProvider>
    </BrowserRouter>
  </React.StrictMode>
));
