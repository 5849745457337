import {
  Alert,
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, LinearProgress,
  MenuItem,
  TextField,
} from '@mui/material';
import React, {useCallback, useState} from 'react';
import {FormattedMessage, FormattedNumber, useIntl} from 'react-intl';
import Box from '@mui/material/Box';
import {Cognito, CreateAccessKeyInput} from '@eolymp/js-sdk/services/cognito/cognito';
import {FormError, useValidationSet} from '@eolymp/ui/dist/form';
import {useClient} from '@eolymp/ui/dist/backend';

const scopes = [
  'asset:image:write',
  'atlas:file:write',
  'atlas:problem:read',
  'atlas:problem:write',
  'atlas:submission:read',
  'atlas:submission:write',
  'cognito:access-key:read',
  'cognito:access-key:write',
  'cognito:role:read',
  'cognito:user:notify',
  'cognito:user:read',
  'community:member:join',
  'community:member:read',
  'community:member:write',
  'executor:runtime:read',
  'helpdesk:auto-reply:read',
  'helpdesk:auto-reply:write',
  'helpdesk:document:read',
  'helpdesk:document:write',
  'helpdesk:ticket:read',
  'helpdesk:ticket:write',
  'judge:contest:participate',
  'judge:contest:read',
  'judge:contest:write',
  'keeper:object:read',
  'keeper:object:write',
  'l10n:project:read',
  'l10n:term:read',
  'l10n:term:write',
  'l10n:translation:read',
  'l10n:translation:write',
  'password_grant',
  'playground:run:read',
  'playground:run:write',
  'ranker:scoreboard:read',
  'ranker:scoreboard:write',
  'taxonomy:topic:link',
  'taxonomy:topic:read',
  'taxonomy:topic:write',
  'typewriter:asset:write',
  'typewriter:fragment:read',
  'typewriter:fragment:write',
  'universe:space:read',
  'universe:space:write',
  'worker:job:read',
  'worker:job:write',
];

interface CreateAccessKeyDialogProps {
  onSubmit: () => void;
  onClose: () => void;
}

export function CreateAccessKeyDialog({onClose, onSubmit}: CreateAccessKeyDialogProps) {
  const intl = useIntl();
  const client = useClient();
  const [value, setValue] = useState<CreateAccessKeyInput>({expiresIn: 2592000});
  const [secret, setSecret] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const validation = useValidationSet(error);

  const handleSubmit = useCallback((value: CreateAccessKeyInput) => {
    setError(undefined);
    setLoading(true);

    new Cognito(client)
        .CreateAccessKey(value)
        .then((out) => setSecret(out.secret || '????'))
        .catch((err) => setError(err))
        .finally(() => setLoading(false));
  }, []);

  if (secret) {
    return (
      <Dialog open={true} maxWidth="sm" fullWidth>
        <DialogTitle>
          <FormattedMessage id="label.new_access_key__dialog_title" defaultMessage="New Access Key" />
        </DialogTitle>
        <DialogContent>
          <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
            <FormattedMessage id="text.access_key_secret_reveal" defaultMessage="Access Key has been created! Please, save token below in a safe location as it won't be shown again." />
            <Alert severity="info">{secret}</Alert>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onSubmit}>
            <FormattedMessage id="action.close" defaultMessage="Close" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
      {loading &&
        <LinearProgress style={{position: 'absolute', left: 0, right: 0, top: 62}} />}
      <DialogTitle>
        <FormattedMessage id="label.new_access_key__dialog_title" defaultMessage="New Access Key" />
      </DialogTitle>
      <DialogContent>
        <Box sx={{display: 'flex', flexDirection: 'column', my: 2, gap: 2}}>
          <FormError errors={validation.get('_')} />
          <TextField
            fullWidth
            disabled={loading}
            variant="outlined"
            error={validation.has('name')}
            value={value.name || ''}
            onChange={(e) => setValue((v) => ({...v, name: e.target.value}))}
            helperText={validation.get('name').join('; ') || intl.formatMessage({id: 'text.name_hint__access_key_form', defaultMessage: 'Give your key descriptive name, for example: Problem Importer Key'})}
            label={intl.formatMessage({id: 'label.name__access_key_form', defaultMessage: 'Name'})}
          />

          <Autocomplete
            fullWidth
            disabled={loading}
            multiple
            value={value.scope ? value.scope?.split(' ') : []}
            onChange={(e, v) => setValue((p) => ({...p, scope: v.join(' ')}))}
            options={scopes}
            renderInput={(props) => (
              <TextField
                {...props}
                fullWidth
                variant="outlined"
                error={validation.has('scope')}
                helperText={validation.get('scope').join('; ') || intl.formatMessage({id: 'text.scope_hint__access_key_form', defaultMessage: 'Define permissions granted to the key.'})}
                label={intl.formatMessage({id: 'label.scope__access_key_form', defaultMessage: 'Scope'})}
              />
            )}
          />

          <TextField
            select
            disabled={loading}
            value={value.expiresIn || ''}
            onChange={(e) => setValue((p) => ({...p, expiresIn: e.target.value ? parseInt(e.target.value, 10) : undefined}))}
            error={validation.has('expires_in')}
            label={intl.formatMessage({id: 'label.expiration__access_key_form', defaultMessage: 'Expiration'})}
            helperText={validation.get('expires_in').join('; ') || intl.formatMessage({id: 'text.expiration__access_key_form', defaultMessage: 'Expiration time helps to ensure key rotation.'})}>
            <MenuItem value={2592000}>
              <FormattedNumber value={30} unit="day" unitDisplay="long" style="unit" />
            </MenuItem>
            <MenuItem value={7776000}>
              <FormattedNumber value={90} unit="day" unitDisplay="long" style="unit" />
            </MenuItem>
            <MenuItem value={15552000}>
              <FormattedNumber value={180} unit="day" unitDisplay="long" style="unit" />
            </MenuItem>
            <MenuItem value={31536000}>
              <FormattedNumber value={1} unit="year" unitDisplay="long" style="unit" />
            </MenuItem>
          </TextField>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit" disabled={loading}>
          <FormattedMessage id="action.cancel" defaultMessage="Cancel" />
        </Button>
        <Button color="primary" onClick={() => handleSubmit(value)} disabled={loading}>
          <FormattedMessage id="action.create__access_key_form" defaultMessage="Create Key" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
