import * as React from 'react';
import {Layout} from './components/app/Layout';
import {Route, Routes} from 'react-router-dom';
import {HomePage} from './pages/app/HomePage';

export const App = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<HomePage />} />
      </Routes>
    </Layout>
  );
};
