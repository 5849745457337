import React from "react";
import Container from "@mui/material/Container";
import {AccessKeyListFragment} from "../../components/keys/AccessKeyListFragment";

export function HomePage() {
  return (
    <Container maxWidth="md">
      <AccessKeyListFragment />
    </Container>
  )
}
