import React from 'react';
import Box from '@mui/material/Box';
import {AppBar} from '@eolymp/ui/dist/layout/AppBar';

export const Layout = ({children}: React.PropsWithChildren) => {
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', bgcolor: '#f7f7f7'}}>
      <AppBar name="Developer Console"/>
      <Box sx={{overflow: 'auto', flex: 1}}>
        {children}
      </Box>
    </Box>
  );
};
