import {createTheme} from '@mui/material';

declare module '@mui/material/styles' {
  interface TypeBackground {
    footer: string;
  }
}

export const Theme = createTheme({
  palette: {
    primary: {main: '#00888c'},
    secondary: {main: '#ffb700'},
    background: {
      footer: '#f5f5f5',
    },
  },
});
