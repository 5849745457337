import React, {useCallback} from 'react';
import {FormattedMessage} from 'react-intl';
import {useClient} from '@eolymp/ui/dist/backend';
import {Cognito} from '@eolymp/js-sdk/services/cognito/cognito';
import {ConfirmationDialog} from '@eolymp/ui/dist/confirmation';

interface DeleteAccessKeyDialogProps {
  accessKey: {id?: string};
  onClose: () => void;
  onSubmit: () => void;
}

export const DeleteAccessKeyDialog: React.FC<DeleteAccessKeyDialogProps> = ({accessKey, onClose, onSubmit}) => {
  const client = useClient();
  const save = useCallback(() => new Cognito(client).DeleteAccessKey({keyId: accessKey?.id}), [client, accessKey?.id]);

  return (
    <ConfirmationDialog
      open={true}
      title={<FormattedMessage id="label.delete_access_key__dialog_title" defaultMessage="Delete Access Key"/>}
      prompt={<FormattedMessage id="text.delete_access_key__confirmation" defaultMessage="Are you sure you want to delete this access key?"/>}
      saveLabel={<FormattedMessage id="action.delete" defaultMessage="Delete"/>}
      save={save}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};
