import React, {useCallback, useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import {FormattedDate, FormattedMessage} from 'react-intl';
import {
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import Box from '@mui/material/Box';
import {AccessKey} from '@eolymp/js-sdk/services/cognito/access_key';
import {Cognito} from '@eolymp/js-sdk/services/cognito/cognito';
import {CreateAccessKeyDialog} from './CreateAccessKeyDialog';
import RemoveIcon from '@mui/icons-material/Clear';
import {DeleteAccessKeyDialog} from './DeleteAccessKeyDialog';
import {useClient} from '@eolymp/ui/dist/backend';

interface DisplayState{
  showCreateDialog?: boolean;
  showDeleteDialog?: boolean;
  key?: AccessKey;
}

function isExpired(key: AccessKey) {
  return key?.expiresAt && (new Date()).getTime() > (new Date(key.expiresAt)).getTime();
}

export function AccessKeyListFragment() {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const [keys, setKeys] = useState<Array<AccessKey>>();
  const [error, setError] = useState<Error>();
  const [display, setDisplay] = useState<DisplayState>({});

  const reload = useCallback(() => {
    setError(undefined);
    setLoading(true);

    new Cognito(client)
        .ListAccessKeys({size: 100})
        .then((out) => setKeys(out.items || []))
        .catch((err) => setError(err))
        .finally(() => setLoading(false));
  }, [client]);

  useEffect(reload, [reload]);

  const handleClose = useCallback(() => setDisplay({}), []);
  const handleSubmit = useCallback(() => {
    reload();
    setDisplay({});
  }, [reload]);

  return (
    <Paper sx={{my: 3}}>
      {display?.showCreateDialog &&
          <CreateAccessKeyDialog
            onClose={handleClose}
            onSubmit={handleSubmit} />}

      {display?.showDeleteDialog &&
          <DeleteAccessKeyDialog
            accessKey={display?.key || {}}
            onClose={handleClose}
            onSubmit={handleSubmit} />}

      <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, px: 3, py: 2}}>
        <Box sx={{display: 'flex', gap: 2, justifyContent: 'space-between'}}>
          <Typography variant="h6" component="h1">
            <FormattedMessage id="label.access_keys__page_title" defaultMessage="Access Keys" />
          </Typography>
          {loading && <CircularProgress size={24} />}
        </Box>
        <Typography variant="body1" sx={{color: 'text.secondary'}}>
          <FormattedMessage id="text.access_keys__section_description" defaultMessage="Access Keys are authentication tokens for Eolymp API. You can use Access Key to create applications which integrate with Eolymp. Remember to keep Access Keys private as they allow to perform actions under your name." />
        </Typography>
      </Box>

      {Boolean(keys?.length) &&
          <Table sx={{tableLayout: 'fixed'}}>
            <TableHead>
              <TableRow>
                <TableCell sx={{pl: 3, width: 0.3}}>
                  <FormattedMessage id="label.name__access_key" defaultMessage="Name" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="label.scope__access_key" defaultMessage="Scope" />
                </TableCell>
                <TableCell sx={{pr: 3, width: 0.1}}>
                  {' '}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {keys?.map((key) => (
                <TableRow>
                  <TableCell sx={{pl: 3}}>
                    <Typography variant="body2">
                      {key.name}
                    </Typography>
                    <Typography variant="caption" sx={{color: isExpired(key) ? 'error.main' : 'text.secondary'}}>
                      <FormattedMessage
                        id="label.expires_on_date__access_key"
                        defaultMessage="Expires on {date}"
                        values={{date: <FormattedDate value={key.expiresAt} dateStyle="short" timeStyle="short" />}}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box sx={{whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis'}}>{key.scope}</Box>
                  </TableCell>
                  <TableCell sx={{pr: 3, textAlign: 'right'}}>
                    <Tooltip title={<FormattedMessage id="action.delete" defaultMessage="Delete" />}>
                      <IconButton edge="end" onClick={() => setDisplay({showDeleteDialog: true, key})}>
                        <RemoveIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>}
      <Box sx={{display: 'flex', justifyContent: 'end', px: 3, py: 2}}>
        <Button onClick={() => setDisplay({showCreateDialog: true})}>
          <FormattedMessage id="action.create_access_key" defaultMessage="Create Access Key" />
        </Button>
      </Box>
    </Paper>
  );
}
