import React from 'react';
import {Navigate} from 'react-router-dom';
import {useUser} from '@eolymp/ui/dist/user';

export const MustAuthenticate = ({children}: React.PropsWithChildren) => {
  const {user} = useUser();

  return user ?
    <>{children}</> :
    <Navigate to="/login"/>;
};

