const messages = {
  get(locale) {
    if (messages.hasOwnProperty(locale)) {
      return messages[locale];
    }

    return {};
  },
  locales: require('./locales.json'),
  az: require('./messages/az.json'),
  en: require('./messages/en.json'),
  kk: require('./messages/kk.json'),
  ru: require('./messages/ru.json'),
  uk: require('./messages/uk.json'),
};

export default messages;
